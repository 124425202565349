<template>

    <div v-if="invoice" class="grid-pad">

      <div >
        <h1 class="oswald f-primary" v-html="$t('invoice.title')"></h1>
        <h2  v-if="invoice.balance != 0 && !invoice.paidOn" class="mb-6 oswald2" v-html="$t('invoice.slogan')"></h2>
        <div v-else>
          <h2 style="text-align: center;" class="mb-6 oswald2 txt-c" >
            <span v-html="$t('invoice.paidText')"></span>
            <span class='invoiceRefTitle'>{{ this.invoice.uId }}</span>
            <span v-html="$t('invoice.paidText2')"></span>
          </h2>
        </div>
      </div>
  
      <!--Section1-->
      <section id="sectionPaiement">
        <div class="grid grid-pad">
          <v-row>
            <v-col class="infoInvoice" style="">
                <div v-if="invoice.balance != 0">
                  <div>
                    <h2 class="f-primary"  v-html="$t('invoice.info')"></h2>
                    <div class="mt-10 grid-pad"> 
                      <v-row >
                        <v-col  class="itemWidth">
                          <v-row class="infoItem">
                            <v-col class="d-flex align-center vColItemName"><h3 class="ItemTitle " v-html="$t('invoice.infoName')"></h3></v-col>
                            <v-col class="d-flex justify-end align-center widthItemTexte" ><p class="ItemTexte F-IE">{{ invoice.accountName }}</p></v-col>
                          </v-row>
                        </v-col>
    
                        <v-col >
                          <v-row class="infoItem">
                            <v-col class="d-flex align-center vColItemRef"><h3 class="ItemTitle" v-html="$t('invoice.infoRef')"></h3></v-col>
                            <v-col class="d-flex justify-end align-center widthItemTexte"><p id="itemUid" class="ItemTexte F-IE">{{ invoice.uId }}</p></v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                        
                  </div>
                  <div class="mt-10">
                    <h2 class="f-primary" v-html="$t('invoice.Items')"></h2>   
                    <div class="" v-if="invoice.items">
                      <v-row  v-for="(item, i) in invoice.items" :key="i" class="grid-pad ItemsBox">
                        <v-row style="width: 100%;">
                          <v-col><p><span class="f-secondary ItemTitle oswald">{{item.number}}</span></p></v-col>
                          <v-col><p> <span class="f-primary F-IE">{{item.total}} $</span></p></v-col>
                        </v-row>
                        <v-row>
                          <v-col><p v-html="$filters.textWithBR(item.description)" ></p></v-col>
                        </v-row>
                          
                      </v-row>
                    </div>
                    <div class="" v-else>
                      <h2 class="subtitleSum" v-html="$t('invoice.noItems')"></h2>
                    </div>
                  </div>
                </div>
    
            </v-col>
            <v-col class="infoPaiement">
              <div  v-if="invoice.balance != 0" class=" ">
                  <v-row class="infoPaiementBG">
                    <v-col class=" pa-0 uppercase">
                        <v-row>
                            <v-col class="d-flex ">
                                <h2 class="oswald" v-html="$t('invoice.summary')"></h2>
                            </v-col>
                        </v-row>

                        <div class="mt-5" v-if="this.invoice">
                            <v-row>
                              <v-radio-group :disabled="loading" data-axId="invoice-grpPaymentTypeId" v-model.number="invoice.paymentTypeId" :messages="paymentTypeMessage">
                                <v-radio :value="3"> <template v-slot:label> <img src="/Resources/images/creditcard.png" /></template> </v-radio>
                                <v-radio :value="7"> <template v-slot:label><img src="/Resources/images/paypal.png" /></template> </v-radio>
                                <!--<v-radio :value="9"> <template v-slot:label>{{$t('invoice.agreement')}}</template> </v-radio>-->
                              </v-radio-group>
                            </v-row>
                            <v-row class="ma-0">
                                <v-col class="pa-0 d-flex justify-start"><p class="sumInfo" v-html="$t('invoice.subtotal')"></p></v-col>
                                <v-col class="pa-0 d-flex justify-end f-primary">
                                    <div v-if="!dirty"><p class="sumInfo" >{{ $filters.$(this.invoice.subTotal) }}$</p></div>
                                    <div v-else>----</div>
                                </v-col>
                            </v-row>
                            <v-row class="ma-0">
                                <v-col class="pa-0 d-flex justify-start"><p class="sumInfo" v-html="$t('invoice.shipping')"></p> </v-col>
                                <v-col class="pa-0 d-flex justify-end f-primary">
                                    <div v-if="!dirty"><p class="sumInfo">{{ $filters.$(this.invoice.shipping) }}$</p></div>
                                    <div v-else>----</div>
                                </v-col>
                            </v-row>
                            <v-row class="ma-0">
                                <v-col class="pa-0 d-flex justify-start"><p class="sumInfo" v-html="$t('invoice.GST')"></p></v-col>
                                <v-col class="pa-0 d-flex justify-end f-primary">
                                    <div v-if="!dirty"><p class="sumInfo">{{ $filters.$(this.invoice.gst) }}$</p></div>
                                    <div v-else>----</div>
                                </v-col>
                            </v-row>
                            <v-row class="ma-0">
                                <v-col class="pa-0 d-flex justify-start"><p class="sumInfo" v-html="$t('invoice.PST')"></p></v-col>
                                <v-col class="pa-0 d-flex justify-end f-primary">
                                    <div v-if="!dirty"><p class="sumInfo">{{ $filters.$(this.invoice.pst) }}$</p></div>
                                    <div v-else>----</div>
                                </v-col>
                            </v-row>
                            <hr class="mt-4" style="width:70%; display: block; margin-left: auto !important; margin-right: auto !important;">
                            <v-row class="mt-4 mx-0">
                                <v-col class="pa-0 d-flex justify-start"><p v-html="$t('invoice.total')"></p></v-col>
                                <v-col class="pa-0 d-flex justify-end f-primary">
                                    <div v-if="!dirty"><p>{{ $filters.$(this.invoice.total) }}$</p></div>
                                    <div v-else>----</div>
                                </v-col>
                            </v-row>
                        </div>

                        <!-- v-btn-->
                        <v-row class="ma-0">

                          <!-- <div v-if="invoice">
                            <v-btn v-if="invoice.balance != 0 && loading == false && !invoice.paidOn" class="mt-5 orderBtn clicker" @click="payInvoice()">
                              <h2 class="orderBtnTitle" >Payer</h2>
                            </v-btn>
                            <div v-if="loading == true">
                              <h2  class="sectionTitle" style="text-align: center;">En traitement</h2>
                              <v-progress-linear color="rgb(var(--primary))" indeterminate striped height="8"></v-progress-linear>
                                <p class="" style="font-weight: 700; cursor: pointer; text-align: center; color: rgb(var(--primary));" @click="cancelInvoice()">Recommencer le paiement</p>
                            </div>
                          </div> -->
                          


                            <v-col  v-if="invoice" cols="12" class="d-flex justify-center">
                              <v-btn v-if="invoice.balance != 0 && loading == false && !invoice.paidOn" @click="payInvoice()" :class="{'gray': loading == true }" class=" orderBtn mt-1" :readonly="loading == true">
                                <h2 class=" light" style="font-size:34px;"  v-html="$t('invoice.paid')"></h2>
                              </v-btn>
                              <div v-if="loading == true">
                                <h2 :class="{'gray': loading == true }" class="" style="font-size:20px;" v-html="$t('invoice.treatment')"></h2>
                                <v-progress-linear class="" color="rgb(var(--primary))" indeterminate striped height="8"></v-progress-linear>
                                <p class="" style="font-size: small; cursor: pointer; text-align: center; color: rgb(var(--primary));" @click="cancelInvoice()" v-html="$t('invoice.refreshPaiement')"></p>
                              </div>

                                
                              <!-- <v-btn v-if="invoice.balance != 0 && loading == false && !invoice.paidOn" @click="payInvoice()" :class="{'gray': loading == true }" class=" orderBtn mt-1" :readonly="loading == true">
                                    <div>
                                        <h2 :class="{'gray': loading == true }" v-if="invoice.paymentTypeId == 7 || loading == true" class="" style="font-size:20px;" v-html="$t('invoice.treatment')"></h2>
                                        <h2 v-if="invoice.paymentTypeId != 7 && loading == false" class=" light" style="font-size:34px;"  v-html="$t('invoice.paid')"></h2>
                                    </div>
                                </v-btn> -->
                            </v-col>

                            <!-- <v-col  cols="12" class="pa-0" style="height:5px;">
                                <v-progress-linear v-if="invoice.paymentTypeId == 7 || loading == true" class="" color="rgb(var(--primary))" indeterminate striped height="8"></v-progress-linear>
                                <p v-if="invoice.paymentTypeId == 7 || loading == true" class="" style="font-size: small; cursor: pointer; text-align: center; color: rgb(var(--primary));" @click="cancelInvoice()" v-html="$t('invoice.refreshPaiement')"></p>
                            </v-col> -->
                        </v-row>
                    </v-col>
                </v-row>
      
              </div>
            </v-col>
          </v-row>
        </div>
      </section>
      <v-dialog v-model="creditCardDialog" persistent>
        <v-card >
          <v-toolbar style="background-color:rgb(var(--secondary)); color: white; padding-left: 20px !important;">
            <v-col>Information de la carte de crédit</v-col>
            <v-col style="max-width: 10%;"><v-btn style="min-width: 30px;" @click="cancelCard">X</v-btn></v-col>
          </v-toolbar>
          <v-card-text style="padding: 50px !important;">
            <div >
              <v-row><v-col><v-text-field class="fieldInfo" v-model="creditCardInfo.CustomerId"  label="Courriel" ></v-text-field></v-col></v-row><!--:rules="CreditRules.customerIds"-->
              <v-row><v-col><v-text-field class="fieldInfo" v-model="creditCardInfo.CustomerName"  label="Acheteur" ></v-text-field></v-col></v-row><!--:rules="CreditRules.customerNames"-->
              <v-row><v-col><v-text-field class="fieldInfo" v-model="creditCardInfo.CreditCard.Owner"  label="Propriétaire de la carte" ></v-text-field></v-col></v-row><!--:rules="CreditRules.owners"-->
              <v-row><v-col><v-text-field class="fieldInfo" v-model="creditCardInfo.CreditCard.Number" :counter="16"  label="Numéro" ></v-text-field></v-col></v-row><!--:rules="CreditRules.numbers"-->
              <v-row>
                <v-col>
                  <v-row>
                    <v-col >
                      <v-text-field style="min-width: 100%;" v-model="creditCardInfo.CreditCard.ExpMonth" :counter="2"  label="MM" ></v-text-field><!--:rules="CreditRules.monthExpirations"-->
                    </v-col>
                    <v-col>
                      <v-text-field style="min-width: 100%;" v-model="creditCardInfo.CreditCard.ExpYear" :counter="2"  label="YY" ></v-text-field><!--:rules="CreditRules.expirations"-->
                    </v-col>
                    <v-col>
                      <v-text-field style="min-width: 100%;" v-model="creditCardInfo.CreditCard.CVV" :counter="3"  label="CVV" ></v-text-field> <!--:rules="CreditRules.cvvs"-->
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col></v-col>
                <v-col></v-col>
                <v-col><v-btn class="mt-2 orderBtn" @click="confirmCard()">
                  <h2 class="orderBtnTitle">Confirmer</h2></v-btn>
                </v-col>
              </v-row>
              <v-row v-if="creditCardError" class="mt-5">
                <v-col>
                  <p style="color: red; text-align: center; max-width: 350px;">Une erreur s'est produite. Veuillez réessayer, et si le problème persiste, veuillez contacter l'administrateur.</p>
                </v-col>
              </v-row>

            </div>

          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </template>
<style>
  div[data-axId="invoice-grpPaymentTypeId"] label { opacity: 1 !important; }
  div[data-axId="invoice-grpPaymentTypeId"] img { height: 35px; width: auto; }
  div[data-axId="invoice-grpPaymentTypeId"] .v-messages { color: red;}
  div[data-axId="invoice-grpPaymentTypeId"] .v-selection-control__input input { cursor: pointer; position: absolute; left: 30%; top: 35%; width: unset; height: unset; opacity: 1; }

</style>
<style scoped>

    .v-dialog { max-width: 600px;}

    .ItemSummary {max-width: 900px; min-width: 600px; display: block; margin-left: auto !important; margin-right: auto !important;  }
    .ItemsBox {position:relative; color: rgba(var(--primary)); width: auto;  height: 100%; background-color: #FFF; box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25); margin: 20px 20px 30px 20px !important; padding: 20px 10px 20px 10px !important;}
    .ItemTitle {font-weight: 400; font-size: 18px;}
    .ItemTexte {text-align: right; margin: 0px 0px 0px 0px !important; font-size: 16px; }
    .vColItemName {max-width: 100px;}
    .vColItemRef { max-width: 400px; }
    .infoItem {min-width: 425px;}
    .sumInfo {font-size: 14px;}
    .orderBtnTitle {font-weight: lighter; }
    .orderBtn{display: block; height:50px !important; background-color: transparent; border: 1px solid gray; border-radius: 10px; width:240px;}
    .orderBtn:hover {background-color: rgb(var(--primary));}
    .orderBtn:hover .orderBtnTitle { color: rgb(var(--tierce));} 

    .infoPaiement { min-width: 400px; max-width: 400px; margin-left: auto; margin-right: auto; margin-bottom: 50px;}
    .infoInvoice {min-width: 850px; margin: 20px 0px 20px 0px;}
    .linkText {cursor: pointer;}
    .fieldInfo { min-width: 350px; }
    @media handheld, only screen and (max-width: 600px) { .infoInvoice { min-width: unset !important; }}

    @media handheld, only screen and (max-width: 960px) {
      .infoPaiement { min-width: 100%; max-width: unset; margin-bottom: 50px; margin-left: auto; margin-right: auto; }
      .fieldInfo { min-width: 100%; }
      .infoInvoice {min-width: 600px;}
      .ItemSummary {width: 100%; min-width: unset;}
      .infoItem {min-width: 100%;}
      .vColItemName {max-width: 100%; min-width: 100%; width: 100%;}
      .vColItemRef { max-width: 100%; min-width: 100%; width: 100%; }
      .itemWidth { min-width: 100%; }
      .ItemTexte {text-align: center;}
    }

    @media handheld, only screen and (max-width: 1279px) {}
    @media handheld, only screen and (max-width: 1378px) {}
</style>

<script>
import Summary from '@/components/infoPayment.vue'
import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js';
import { navigateRouteExtension } from '@/mixins/navigateRouteExtension.js';
import pageTranslations from '../../../src/assets/i18N/eCommerce/invoice.json';

export default {
    name: 'Invoice', 
    mixins: [apiECommerceExtension, navigateRouteExtension],
    props: {
        summary: Object,
    },
    data: () => ({
      invoice:null,
      loading: false,
      invoiceToPaid: true,
      paymentTypeMessage: '',
      creditCardDialog: false,
      creditCardError:false,
      creditCardInfo: {
        CustomerId:"",
        CustomerName:"",
        //CreditCard:{ Number:null, Owner:null, CVV:null, ExpMonth:null, ExpYear:null, Exp:""}
        CreditCard:{ Number:"4242424242424242", Owner:"??????", CVV:"123", ExpMonth:"01", ExpYear:"29", Exp:""}

      },
      CreditRules:{
        customerNames: [
          value => {
            if (value?.length > 0) {
              return true
            }
            return 'obligatoire'
          },
        ],
        customerIds: [
          value => {
            if (value?.length > 0) {
              return true
            }
            return 'obligatoire'
          },
        ],
        owners: [
          value => {
            if (value?.length > 4) {
              return true
            }
            return 'trop court'
          },
        ],
        numbers: [
          value => {
            value.replace(/[^0-9]/g, '');
            if (value?.length == 16) {
              return true
            }
            return 'Invalide'
          },
        ],
        expirations: [
          value => {
            value.replace(/[^0-9]/g, '');

            if (value?.length == 2) {
              return true}
            return "2 chiffres"
          },
        ],
        monthExpirations: [
          value => {
            value.replace(/[^0-9]/g, '');
            if (value?.length == 2) { if(value >= 1 && value <= 12){ return true } }
            return "2 chiffres"
          },
        ],
        cvvs: [
          value => {
            value.replace(/[^0-9]/g, '');
            if (value?.length == 3) {return true}
            return "3 chiffres"
          },
        ],
      },
    }),
    watch: {
      'invoice.paymentTypeId': function(val) { this.paymentTypeMessage = "";  },
      'creditCardInfo.CreditCard.Number': function(val) { this.creditCardInfo.CreditCard.Number = val.replace(/[^0-9]/g, ''); },
      'creditCardInfo.CreditCard.ExpMonth': function(val) { this.creditCardInfo.CreditCard.ExpMonth = val.replace(/[^0-9]/g, ''); },
      'creditCardInfo.CreditCard.ExpYear': function(val) { this.creditCardInfo.CreditCard.ExpYear = val.replace(/[^0-9]/g, ''); },
      'creditCardInfo.CreditCard.CVV': function(val) { this.creditCardInfo.CreditCard.CVV = val.replace(/[^0-9]/g, ''); },
    },
    beforeCreate: function () {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);
    },

    mounted: async function () {
      let param = this.$route.params.id;
      if(!param){ 
        param = this.GetInvoiceRef();
        if(param){ param = param.replace(/"/g,'') } else { this.$router.push('/'); };
      } 
      
      await this.readData(param);

      if(this.invoice && this.invoice.balance == 0 && this.invoice.paidOn){
        let paramReload = this.$route.params.id;
        let cleared = await this.ClearInvoice();

        if(!cleared){ paramReload = this.GetInvoiceRef(); }
        if(!paramReload){ this.$router.push('/'); }
      }


  },
  methods: {
    readData: async function(param){
      let data = await this.GetInvoice(param);
      if(data) {
        if(this.creditCardInfo) {
          this.creditCardInfo.CustomerName = data.accountName;
          if (data.comment) {
            let emails = data.comment.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
            if (emails && Array.isArray(emails) && emails.length > 0) {
              this.creditCardInfo.CustomerId = emails[0];
            }
          }
        }
        if(data.paymentTypeId != 0){ this.loading = true; }
        this.invoice = data;

      } else { this.$router.push('/'); }

      
    },
    payInvoice: async function(){
      if(this.invoice.paymentTypeId == 7) {
        this.loading = true;
        let urlToPay = '';

        if (this.invoice.uId) { urlToPay = await this.PaypalInit(this.invoice.uId); }
        if (urlToPay != "") { window.location.href = urlToPay; }

      } else if (this.invoice.paymentTypeId == 3) {
        this.creditCardDialog = true;
      } else if (this.invoice.paymentTypeId == 9) { 
        this.paymentTypeMessage = this.$t('invoice.agreementMessage')
      }
      else { this.paymentTypeMessage = "Obligatoire";}

    },
    confirmCard: async function(){
      let valid = true;
      this.creditCardError = false;

      if(this.creditCardInfo.CreditCard){
        if(!this.creditCardInfo.CustomerId || this.creditCardInfo.CustomerId.length < 1 ) { valid = false; }
        else if(!this.creditCardInfo.CustomerName || this.creditCardInfo.CustomerName.length < 1 ) { valid = false;  }
        else if(!this.creditCardInfo.CreditCard.Owner || this.creditCardInfo.CreditCard.Owner.length < 5 ) { valid = false; }
        else if(!this.creditCardInfo.CreditCard.Number || this.creditCardInfo.CreditCard.Number.length !== 16 ) { valid = false; }
        else if(!this.creditCardInfo.CreditCard.ExpMonth || this.creditCardInfo.CreditCard.ExpMonth.length !== 2 ) { valid = false; }
        else if(!this.creditCardInfo.CreditCard.ExpYear || this.creditCardInfo.CreditCard.ExpYear.length !== 2 ) { valid = false; }
        else if(!this.creditCardInfo.CreditCard.CVV || this.creditCardInfo.CreditCard.CVV.length !== 3 ) { valid = false; }
      } else { valid = false;}

      if(valid == true) {
        this.creditCardDialog = false;
        this.loading = true;
        console.log('invoice: ' + this.invoice.uId);
        console.log(this.creditCardInfo);
        let err = await this.CreditCardPaiement(this.invoice.uId,this.creditCardInfo);
        console.log(err);
        if(err) {
          this.creditCardDialog = true;
          this.creditCardError = true;
          this.loading = false;
        } else { window.location.reload(); }

        // let url = '';
        // if(this.invoice.uId){ url = await this.CreditCardPaiement(this.invoice.uId,this.creditCardInfo); }
        // if(url == true){ window.location.reload();   } else { this.creditCardDialog = true; }

      } else {
        if(this.creditCardDialog != true) {this.creditCardDialog = true;}
        this.creditCardError = true;
        this.loading = false;
      }

    },
    cancelCard: async function(){
      this.loading = false;
      this.creditCardDialog = false;
      this.creditCardError = false;
    },
    cancelInvoice: async function(){
      if(this.invoice && this.invoice.uId ) { 
        if(this.invoice.paymentTypeId == 7) {
          this.$router.push('/invoices/'+ this.invoice.uId + '/paypal/canceled');
        } else { window.location.reload(); }
      }
      // let param = this.$route.params.id;
      // if(!param){ param = this.GetInvoiceRef(); param = param.replace(/"/g,'');}
      // this.$router.push('/invoices/'+ param + '/paypal/canceled');
    },
    navigateTo: function () {
      this.$router.push('/Boutique');
    },
  },
  
  components: {
    Summary
  },
}
</script>